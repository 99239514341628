<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    max-width="800"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Courier Status</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row">
          <!--begin::Body-->
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.carrier_id"
              label="Carrier"
              item-text="name"
              item-value="id"
              :items="serverData.carriers"
              clearable
              outlined
              dense
              :error-messages="carrier_idErrors"
              @input="$v.formData.carrier_id.$touch()"
              @blur="$v.formData.carrier_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.status_id"
              label="Status"
              item-text="name"
              item-value="id"
              :items="serverData.status"
              clearable
              outlined
              dense
              :error-messages="status_idErrors"
              @input="$v.formData.status_id.$touch()"
              @blur="$v.formData.status_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData['3pl_status']"
              label="3pl status"
              clearable
              outlined
              dense
              :error-messages="_3pl_statusErrors"
              @input="$v.formData['3pl_status'].$touch()"
              @blur="$v.formData['3pl_status'].$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.skip"
              label="Skip"
              item-text="value"
              item-value="index"
              :items="serverData.attributes.skip"
              clearable
              outlined
              dense
              :error-messages="skipErrors"
              @input="$v.formData.skip.$touch()"
              @blur="$v.formData.skip.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.type_id"
              label="Type"
              item-text="value"
              item-value="index"
              :items="serverData.attributes.type"
              clearable
              outlined
              dense
              :error-messages="typeErrors"
              @input="$v.formData.type.$touch()"
              @blur="$v.formData.type.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.integration"
              label="Integration"
              item-text="value"
              item-value="index"
              :items="serverData.attributes.integration"
              clearable
              outlined
              dense
              :error-messages="integrationErrors"
              @input="$v.formData.integration.$touch()"
              @blur="$v.formData.integration.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.rts_in"
              label="RTS in"
              item-text="value"
              item-value="index"
              :items="serverData.attributes.rts_in"
              clearable
              outlined
              dense
              :error-messages="rts_inErrors"
              @input="$v.formData.rts_in.$touch()"
              @blur="$v.formData.rts_in.$touch()"
            ></v-select>
          </div>
        </div>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      carrier_id: { required },
      status_id: { required },
      "3pl_status": { required },
      skip: { required },
      type_id: { required },
      rts_in: { required },
      integration: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      carrier_id: null,
      status_id: null,
      "3pl_status": null,
      skip: null,
      type_id: null,
      rts_in: null,
      integration: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { carrier_status_id: this.$store.getters.getItemForAction.id };
      ApiService.post("/shipping/courierStatus/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = { ...this.serverData.carrier_status };
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      data.append("carrier_status_id", this.$store.getters.getItemForAction.id);
      Object.entries(this.formData).forEach((item) => {
        if ((item[1] && item[0] !== "image") || item[1] === 0) {
          data.append(item[0], item[1]);
        } else if (item[1] && item[0] === "image") {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        ApiService.post("/shipping/courierStatus/update", data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Courier status data has been updated!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        carrier_id: null,
        status_id: null,
        "3pl_status": null,
        skip: null,
        type_id: null,
        rts_in: null,
        integration: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    carrier_idErrors: function () {
      return this.handleFormValidation("carrier_id", this);
    },
    status_idErrors: function () {
      return this.handleFormValidation("status_id", this);
    },
    _3pl_statusErrors: function () {
      return this.handleFormValidation("3pl_status", this);
    },
    skipErrors: function () {
      return this.handleFormValidation("skip", this);
    },
    typeErrors: function () {
      return this.handleFormValidation("type_id", this);
    },
    rts_inErrors: function () {
      return this.handleFormValidation("rts_in", this);
    },
    integrationErrors: function () {
      return this.handleFormValidation("integration", this);
    },
  },
};
</script>
